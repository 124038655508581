<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="600px"
      height="600px"
    >
      <v-card>
        <!-- ประวัติแก้ไขจำกัดการรับ e-tax -->
        <v-card-title>{{$t("consent.history")}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:600px">
          <v-layout style="justify-content: center;" class="mt-5">
            <h3 class="mr-1">
              {{ $t("consent.Status") }} :
              <!-- {{
                data.status_accept === "0"
                  ? $t("consent.receive_all")
                  : data.status_accept === "1"
                  ? $t("consent.receive_specificate")
                  : $t("consent.receive_all_with_except")
              }} -->
            </h3>
            <h3 v-if="data.status_accept === '0'" style="color:#C51D1D"> {{$t("consent.receive_all_with_except")}} </h3>
            <h3 v-else-if="data.status_accept === '1'" style="color:#E59726"> {{$t("consent.receive_specificate")}} </h3>
            <h3 v-else-if="data.status_accept === '2'" style="color:#338300"> {{$t("consent.receive_all")}} </h3>
          </v-layout>
          <!-- {{ data }} -->
          <v-col cols="12" class="mb-n12" style="margin-top:10px">
            <v-card-text>
                <b>{{ $t("consent.name_user") }} : </b>
                <b v-if="$t('default') === 'th'">{{data.name_th === null ? 'ระบบ' : data.name_th}}</b>
                <b v-else>{{data.name_eng === null ? 'System' : data.name_eng}}</b>
                <br>
                <b>{{ $t("consent.modified_date") }} : </b> {{formatdatetime(data.process_dtm)}}
            </v-card-text>
            <v-card-text
              class="pa-0 pt-0 ml-5 mt-1"
              style="font-size:16px;color:#1F1F1F"
            >
              <b>
                <!-- จำนวน -->
                {{ $t("consent.total") }} :
                <!-- แสดงจำนวนบริษัททั้งหมด -->
                <!-- {{datataxid.block_taxid.length}} {{ $t("consent.company") }} -->
                {{ total_taxid }} {{ $t("consent.company") }}
                <!-- <v-icon small color="#C51D1D">mdi-close-circle</v-icon> -->
              </b>
            </v-card-text>
            <v-flex
              style="max-height:285px;background-color:white;border:1px solid #EDEDFF;border-radius:5px;"
              class="overflow-y-auto"
            >
              <v-list-item-group v-model="list_taxid">
                <!-- แสดงข้อมูล 3 ค่า (ก่อนกดแสดงเพิ่มเติม)-->
                <div v-if="show_more_data_list_tax === false">
                  <v-list-item
                    dense
                    v-for="(item, index) in data_list_tax.slice(0, 3)"
                    :key="index"
                    close
                    class="ma-1"
                    style="font-size:20px;"
                  >
                    <v-list-item-content class="pt-1">
                      <!-- <v-list-item-title class="mb-5" v-text="item.taxid"></v-list-item-title> -->
                      <!-- <v-list-item-title v-text="$t('default') === 'th' ? item.name_th : item.name_eng"></v-list-item-title> -->
                      <v-list-item-title
                        style="font-size:13px;line-height: 20px;"
                      >
                        {{ item.taxid }} :
                        <br />
                        {{
                          $t("default") === "th" ? item.name_th : item.name_eng
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <!-- คลิ๊กแล้วปุ่มจะแสดง -->
                    <!-- v-if="model === index" -->
                    <!-- <v-btn
                          @mouseover="show_delete = true"
                          @mouseleave="show_delete = false"
                          icon
                          x-small
                          @click="delete_block_taxid(index)"
                          >
                            <v-icon
                            >mdi-close-circle</v-icon>
                          </v-btn> -->
                    <br />
                  </v-list-item>
                  <!-- </v-list-item-group> -->
                </div>
                <!-- แสดงข้อมูลทั้งหมด (หลังกดแสดงเพิ่มเติม)-->
                <div v-else>
                  <v-list-item
                    dense
                    v-for="(item, index) in data_list_tax"
                    :key="index"
                    close
                    class="ma-1"
                    style="front-size:20px;"
                  >
                    <v-list-item-content class="pt-1">
                      <!-- <v-list-item-title class="mb-5" v-text="item.taxid"></v-list-item-title> -->
                      <!-- <v-list-item-title v-text="$t('default') === 'th' ? item.name_th : item.name_eng"></v-list-item-title> -->
                      <v-list-item-title
                        style="font-size:13px;line-height: 20px;"
                      >
                        {{ item.taxid }} :
                        <br />
                        {{
                          $t("default") === "th" ? item.name_th : item.name_eng
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <br />
                  </v-list-item>
                </div>
              </v-list-item-group>
              <!--จำนวนบริษัทน้อยกว่า 3 บริษัท จะไม่แสดงเพิ่มเติมกับไม่แสดงน้อยลง -->
              <v-layout v-if="total_taxid > 3">
                <!-- ...แสดงเพิ่มเติม -->
                <p
                  v-if="show_more_data_list_tax === false"
                  class="ml-5"
                  style="front-size:10px;cursor:pointer;color:#6495eddb;"
                  color="primary"
                  @click="show_more()"
                >
                  {{ $t("consent.show_more") }}
                </p>
                <!-- ...แสดงน้อยลง -->
                <p
                  v-else
                  class="ml-5"
                  style="front-size:10px;cursor:pointer;color:#6495eddb;"
                  color="primary"
                  @click="show_less()"
                >
                  {{ $t("consent.show_less") }}
                </p>
              </v-layout>
            </v-flex>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มยกเลิก -->
          <v-btn color="#C51D1D" outlined @click="$emit('close')">
            {{ $t("consent.close") }}</v-btn
          >
          <!-- ปุ่มยืนยัน -->
          <!-- <v-btn
            color="#479F31"
            class="white--text"
            @click="check_btn(),clear()"
          >
            {{ $t("consent.save") }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "data"],
  data() {
    return {
      data_list_tax: [],
      data_tax: [],
      total_taxid: "",
      show_more_data_list_tax: false,
      list_taxid: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.listconsent.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.listconsent.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("show", this.show);
          this.fn_get_nametax();
        }
        return this.show;
      },
    },
  },
  methods: {
    show_more() {
      this.show_more_data_list_tax = true;
    },
    show_less() {
      this.show_more_data_list_tax = false;
    },
    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
    async fn_get_nametax() {
      this.total_taxid = 0;
      this.data_list_tax = [];
      console.log("ยิง taxid", this.data.setting_list);
      for (let i = 0; i < this.data.setting_list.length; i++) {
        console.log("data setting list", this.data.setting_list[i]);

        let payload = {
          taxid: this.data.setting_list[i],
        };
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_AUTHORIZE_API +
              "/api/v1/get_business_name_by_taxid",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              console.log("ok getname_taxid");
              this.data_tax = response.data.data;
              console.log("data_tax",this.data_tax);

              this.data_list_tax.push({
                taxid: this.data.setting_list[i],
                name_th: this.data_tax.name_th,
                name_eng: this.data_tax.name_eng,
              });
              // จำนวนบริษัททั้งหมด
              this.total_taxid = this.data.setting_list.length;
            } else {
              Toast.fire({
                icon: "error",
                title:
                  response.data.errorCode + ": " + response.data.errorMessage,
              });
            }

            if (i === this.data.setting_list.length - 1) {
              console.log("ครบ");
            }
          })
          .catch((error) => {
            console.log("error", error);
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
      }
    },
  },
};
</script>

<style>
</style>
